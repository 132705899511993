export const payment = {
  paymentDetails:{
    en: "Payment Details",
    ja: "支払方法",
    et: "Makse andmed",
  },
  addPaymentMethod:{
    en: "Add Payment Method",
    ja: "支払方法追加する",
    et: "Lisa maksemeetod",
  },
  expires:{
    en: "Expires",
    ja: "期限切れ",
    et: "Aegub",
  },
  default:{
    en: "Default",
    ja: "デフォルト",
    et: "Vaikimis",
  },
  makeDefault:{
    en: "Make Default",
    ja: "デフォルトにする",
    et: "Määra vaikimisi valikuks",
  },
  noPaymentMethods:{
    en: "Add a payment method to easily make purchases or maintain subscriptions. ",
    ja: "支払い方法を追加して、簡単に購入したり、サブスクリプションを維持したりします。",
    et: "Lisa maksemeetod, et lihtsasti osta või tellimusi hallata",
  },
  stripeNotice:{
    en: "Your payment information is securely saved via Stripe. You can read more about how they handle your data via their website <0>stripe.com/privacy</0>.",
    ja: "お支払い情報はStripe経由で安全に保存されます。 Stripeのウェブサイト<0>stripe.com/privacy</0>を通してあなたのデータをどのように扱うかの詳細を読めます。",
    et: "Sinu maksete andmed on turvaliselt salvestatud Stripe abil. Stripe lehelt <0>stripe.com/privacy</0> saad rohkem teada, kuidas sinu andmeid kaitstakse."
  },
  creditCardEmpty:{
    en: "Name on Card cannot be empty",
    ja: "カードの名前は空欄にできません",
    et: "Nimi kaardil on kohustuslik",
  },
  nameOnCard:{
    en: "Name on Card",
    ja: "カードの名前",
    et: "Nimi kaardil"
  },
  cardDetails:{
    en: "Card Details",
    ja: "カード詳細",
    et: "Kaardi andmed",
  },
  useDifferentPaymentMethod:{
    en: "Change Payment Method",
    ja: "違う支払い方法選択する",
    et: "Muuda maksemeetodit",
  },

  unlockPremiumRightNow:{
    en: "Unlock Premium Today",
    ja: "今すぐプレミアムにアップグレードする",
    et: "Lukusta lahti Premium",
  },
  checkout:{
    en: "Checkout",
    ja: "チェックアウト",
    et: "Kassasse",
  },

  premium: {
    en: "Premium",
    ja: "プレミアム",
    et: "Premium",
  },

  billingRate:{
    en: "Billing Rate",
    ja: "請求レート",
    et: "Arveldamise periood",
  },
  billedAnnually:{
    en: "Billed Annually",
    ja: "毎年請求",
    et: "Kord aastas"
  },
  billMonthly:{
    en: "Billed Monthly",
    ja: "毎月請求",
    et: "Kord kuus",
  },
  perMonth: {
    en: "Per Month",
    ja: "月額",
    et: "Iga kuu eraldi",
  },
  savings:{
    en: "Save {{percentage}}",
    ja: "セーブ {{percentage}}",
    et: "Salvesta {{percentage}}",
  },

  planSummary:{
    en: "Plan Summary",
    ja: "プラン概要",
    et: "Plaani ülevaade",
  },

  total:{
    en: "Total",
    ja: "合計",
    et: "Kokku",
  },
  savingsMessage:{
    en: "You're saving {{percentage}} with this plan",
    ja: "このプランで{{percentage}}節約です",
    et: "Selle plaaniga säästad {{percentage}}",
  },
  missedSavingsMessage:{
    en: "Switch to annual billing and save {{percentage}}",
    ja: "年次請求に切り替えて{{percentage}}を節約できます",
    et: "Vaheta kord aastas arveldusele ja säästa {{percentage}}",
  },
  upgrade:{
    en: "Upgrade",
    ja: "アップグレードする",
    et: "Muuda",
  },

  
}

