import React,{useState} from 'react';
import './dawipay.scss';
import DawicoinReactSdk from 'dawicoin-react-sdk';

const Dawipay = ({newAccountStatus,priceUid,callback}) => {

  let [price,setPrice] = useState("price_sYtUyKRvKf4toJSr9MqRGg");
  let [euid,setEuid] = useState("");
  let storeUid = "store_oM85WhQDWkBcnTnCPh6xew";

  return (
    <div className="dawipay-comp">
      <DawicoinReactSdk sandbox={false}
        storeUid={storeUid}
        priceUid={price}
        euid={euid}
        callback={({completed,paymentUid,subUid}) => {
          // completed: Bool, paymentUid: String?, subUid: String?
          // do stuff
          // callback();
        }}
        />
    </div>
  );

};

export default Dawipay;