export const roboTracks = {
  "infinity-icon": {
    "en": "Eternal Circuit",
    "ja": "エターナル・サーキット"
  },
  "stellar-vanguard": {
    "en": "Starlight Speedway",
    "ja": "スターライト・スピードウェイ"
  },
  "nexus-strider": {
    "en": "Nexus Rushway",
    "ja": "ネクサス・ラッシュウェイ"
  },
  "aurora-sentinel": {
    "en": "Aurora Watchway",
    "ja": "オーロラ・ウォッチウェイ"
  },
  "chrono-enforcer": {
    "en": "Chrono Enforcement Circuit",
    "ja": "クロノ・エンフォースメント・サーキット"
  },
  "omega-apex": {
    "en": "Omega Apex Raceway",
    "ja": "オメガ・エイペックス・レースウェイ"
  },
  "nebula-spark": {
    "en": "Nebula Ignition Track",
    "ja": "ネビュラ・イグニッション・トラック"
  },
  "galactic-fury": {
    "en": "Galactic Fury Speedway",
    "ja": "ギャラクティック・フュリー・スピードウェイ"
  },
  "celestial-echo": {
    "en": "Celestial Echo Circuit",
    "ja": "セレスティアル・エコー・サーキット"
  },
  "titan-forge": {
    "en": "Titan Forge Speedway",
    "ja": "タイタン・フォージ・スピードウェイ"
  },
  "solaris-guardian": {
    "en": "Solaris Guardian Track",
    "ja": "ソラリス・ガーディアン・トラック"
  },
  "pulse-raider": {
    "en": "Pulse Raider Raceway",
    "ja": "パルス・レイダー・レースウェイ"
  },
  "aero-dynamo": {
    "en": "Aero Dynamo Circuit",
    "ja": "エアロ・ダイナモ・サーキット"
  },
  "xenon-havoc": {
    "en": "Xenon Havoc Speedway",
    "ja": "キセノン・ハボック・スピードウェイ"
  },
  "radiant-expanse": {
    "en": "Radiant Expanse Track",
    "ja": "レディアント・エクスパンス・トラック"
  },
  "zenith-nova": {
    "en": "Zenith Nova Circuit",
    "ja": "ゼニス・ノヴァ・サーキット"
  },
  "serenity-warden": {
    "en": "Serenity Warden Speedway",
    "ja": "セレニティ・ウォーデン・スピードウェイ"
  },
  "vertex-pulse": {
    "en": "Vertex Pulse Track",
    "ja": "ヴァーテックス・パルス・トラック"
  },
  "orion-seraph": {
    "en": "Orion Seraph Raceway",
    "ja": "オリオン・セラフ・レースウェイ"
  },
  "luna-specter": {
    "en": "Luna Specter Circuit",
    "ja": "ルナ・スペクター・サーキット"
  },
  "astral-blitz": {
    "en": "Astral Blitz Speedway",
    "ja": "アストラル・ブリッツ・スピードウェイ"
  },
  "inferno-ascent": {
    "en": "Inferno Ascent Track",
    "ja": "インフェルノ・アッセント・トラック"
  },
  "mirage-valkyrie": {
    "en": "Mirage Valkyrie Raceway",
    "ja": "ミラージュ・ヴァルキリー・レースウェイ"
  },
  "quantum-shard": {
    "en": "Quantum Shard Circuit",
    "ja": "クォンタム・シャード・サーキット"
  },
  "cybernetic-dawn": {
    "en": "Cybernetic Dawn Speedway",
    "ja": "サイバネティック・ドーン・スピードウェイ"
  },
  "phoenix-sentinel": {
    "en": "Phoenix Sentinel Track",
    "ja": "フェニックス・センチネル・トラック"
  },
  "hyperion-aegis": {
    "en": "Hyperion Aegis Raceway",
    "ja": "ハイペリオン・イージス・レースウェイ"
  },
  "echelon-echo": {
    "en": "Echelon Echo Circuit",
    "ja": "エシュロン・エコー・サーキット"
  },
  "vector-luminary": {
    "en": "Vector Luminary Speedway",
    "ja": "ベクター・ルミナリー・スピードウェイ"
  },
  "techno-raider": {
    "en": "Techno Raider Track",
    "ja": "テクノ・レイダー・トラック"
  },
  "astro-flame": {
    "en": "Astro Flame Circuit",
    "ja": "アストロ・フレイム・サーキット"
  },
  "arcane-enigma": {
    "en": "Arcane Enigma Speedway",
    "ja": "アーケイン・エニグマ・スピードウェイ"
  },
  "blade-stryker": {
    "en": "Blade Stryker Raceway",
    "ja": "ブレード・ストライカー・レースウェイ"
  },
  "pyro-goliath": {
    "en": "Pyro Goliath Track",
    "ja": "パイロ・ゴライアス・トラック"
  },
  "radiant-pinnacle": {
    "en": "Radiant Pinnacle Circuit",
    "ja": "レディアント・ピナクル・サーキット"
  },
  "volt-vanguard": {
    "en": "Volt Vanguard Speedway",
    "ja": "ボルト・ヴァンガード・スピードウェイ"
  },
  "nebula-nova": {
    "en": "Nebula Nova Track",
    "ja": "ネビュラ・ノヴァ・トラック"
  },
  "stellaris-specter": {
    "en": "Stellaris Specter Raceway",
    "ja": "ステラリス・スペクター・レースウェイ"
  },
  "skyward-asylum": {
    "en": "Skyward Asylum Circuit",
    "ja": "スカイワード・アサイラム・サーキット"
  },
  "nova-nebulon": {
    "en": "Nova Nebulon Speedway",
    "ja": "ノヴァ・ネビュロン・スピードウェイ"
  },
  "infinity-warden": {
    "en": "Infinity Warden Track",
    "ja": "インフィニティ・ウォーデン・トラック"
  },
  "zenith-zenith": {
    "en": "Zenith Zenith Circuit",
    "ja": "ゼニス・ゼニス・サーキット"
  },
  "omega-nebula": {
    "en": "Omega Nebula Speedway",
    "ja": "オメガ・ネビュラ・スピードウェイ"
  },
  "celestial-surge": {
    "en": "Celestial Surge Track",
    "ja": "セレスティアル・サージ・トラック"
  },
  "aurora-atlas": {
    "en": "Aurora Atlas Circuit",
    "ja": "オーロラ・アトラス・サーキット"
  },
  "nexus-nighthawk": {
    "en": "Nexus Nighthawk Speedway",
    "ja": "ネクサス・ナイトホーク・スピードウェイ"
  },
  "pulse-prism": {
    "en": "Pulse Prism Track",
    "ja": "パルス・プリズム・トラック"
  },
  "nebula-nemesis": {
    "en": "Nebula Nemesis Raceway",
    "ja": "ネビュラ・ネメシス・レースウェイ"
  },
  "solaris-shifter": {
    "en": "Solaris Shifter Circuit",
    "ja": "ソラリス・シフター・サーキット"
  },
  "quantum-quasar": {
    "en": "Quantum Quasar Speedway",
    "ja": "クォンタム・クエーサー・スピードウェイ"
  },
  "xenon-xplorer": {
    "en": "Xenon Xplorer Track",
    "ja": "キセノン・エクスプローラー・トラック"
  },
  "serenity-savant": {
    "en": "Serenity Savant Circuit",
    "ja": "セレニティ・サヴァント・サーキット"
  },
  "aero-argonaut": {
    "en": "Aero Argonaut Speedway",
    "ja": "エアロ・アルゴノート・スピードウェイ"
  },
  "apex-alchemist": {
    "en": "Apex Alchemist Raceway",
    "ja": "エイペックス・アルケミスト・レースウェイ"
  },
  "luna-luminous": {
    "en": "Luna Luminous Circuit",
    "ja": "ルナ・ルミナス・サーキット"
  },
  "astral-automaton": {
    "en": "Astral Automaton Speedway",
    "ja": "アストラル・オートマトン・スピードウェイ"
  },
  "orion-octane": {
    "en": "Orion Octane Track",
    "ja": "オリオン・オクタン・トラック"
  },
  "vertex-vanguard": {
    "en": "Vertex Vanguard Circuit",
    "ja": "ヴァーテックス・ヴァンガード・サーキット"
  },
  "radiant-ronin": {
    "en": "Radiant Ronin Speedway",
    "ja": "レディアント・ローニン・スピードウェイ"
  },
  "inferno-ignition": {
    "en": "Inferno Ignition Track",
    "ja": "インフェルノ・イグニッション・トラック"
  },
  "mirage-maven": {
    "en": "Mirage Maven Raceway",
    "ja": "ミラージュ・メイヴン・レースウェイ"
  },
  "techno-tinkerer": {
    "en": "Techno Tinkerer Circuit",
    "ja": "テクノ・ティンカラー・サーキット"
  },
  "arcane-arbiter": {
    "en": "Arcane Arbiter Speedway",
    "ja": "アーケイン・アービター・スピードウェイ"
  },
  "phoenix-paragon": {
    "en": "Phoenix Paragon Track",
    "ja": "フェニックス・パラゴン・トラック"
  },
  "echelon-epoch": {
    "en": "Echelon Epoch Circuit",
    "ja": "エシュロン・エポック・サーキット"
  },
  "vector-vagabond": {
    "en": "Vector Vagabond Speedway",
    "ja": "ベクター・ヴァガボンド・スピードウェイ"
  },
  "pyro-paladin": {
    "en": "Pyro Paladin Track",
    "ja": "パイロ・パラディン・トラック"
  },
  "astro-adept": {
    "en": "Astro Adept Circuit",
    "ja": "アストロ・アデプト・サーキット"
  },
  "blade-byte": {
    "en": "Blade Byte Speedway",
    "ja": "ブレード・バイト・スピードウェイ"
  },
  "nova-nomad": {
    "en": "Nova Nomad Circuit",
    "ja": "ノヴァ・ノマド・サーキット"
  },
  "infinity-instigator": {
    "en": "Infinity Instigator Speedway",
    "ja": "インフィニティ・インスティゲーター・スピードウェイ"
  }
};
