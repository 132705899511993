import React,{useRef} from 'react';
import './button.scss'

import Loading from '../../common/loading/loading';
import {ReactComponent as VisibilityOnSvg} from '../../../css/imgs/icon-visibility-on.svg';
import {ReactComponent as VisibilityOffSvg} from '../../../css/imgs/icon-visibility-off.svg';
import {ReactComponent as AddSvg} from '../../../css/imgs/icon-add.svg';
import {ReactComponent as EditSvg} from '../../../css/imgs/icon-edit.svg';
import {ReactComponent as SaveSvg} from '../../../css/imgs/icon-save.svg';
import {ReactComponent as DeleteSvg} from '../../../css/imgs/icon-delete.svg';
import {ReactComponent as CloseSvg} from '../../../css/imgs/icon-close.svg';
import {ReactComponent as ContinueSvg} from '../../../css/imgs/icon-arrow-forward.svg';
import {ReactComponent as RefreshSvg} from '../../../css/imgs/icon-refresh.svg';
import {ReactComponent as SearchSvg} from '../../../css/imgs/icon-search.svg';
import {ReactComponent as SearchAdvSvg} from '../../../css/imgs/icon-search-advanced.svg';
import {ReactComponent as CopySvg} from '../../../css/imgs/icon-copy.svg';
import {ReactComponent as PowerOffSvg} from '../../../css/imgs/icon-power-off.svg';
import {ReactComponent as PowerOnSvg} from '../../../css/imgs/icon-power-on.svg';
import {ReactComponent as ArchiveSvg} from '../../../css/imgs/icon-archive.svg';
import {ReactComponent as CheckSvg} from '../../../css/imgs/icon-check.svg';
import {ReactComponent as ArrowBackSvg} from '../../../css/imgs/icon-arrow-back.svg';
import {ReactComponent as ArrowForwardSvg} from '../../../css/imgs/icon-arrow-forward.svg';
import {ReactComponent as FilterSvg} from '../../../css/imgs/icon-filter-list.svg';
import {ReactComponent as ArrowUpSvg} from '../../../css/imgs/icon-arrow-up.svg';
import {ReactComponent as ArrowDownSvg} from '../../../css/imgs/icon-arrow-down.svg';
import {ReactComponent as UploadSvg} from '../../../css/imgs/icon-upload.svg';
import {ReactComponent as DownloadSvg} from '../../../css/imgs/icon-download.svg';
import {ReactComponent as PreviewSvg} from '../../../css/imgs/icon-preview.svg';
import {ReactComponent as ShuffleSvg} from '../../../css/imgs/icon-shuffle.svg';
import {ReactComponent as PlaySvg} from '../../../css/imgs/icon-play.svg';
import {ReactComponent as InfoSvg} from '../../../css/imgs/icon-info.svg';
import {ReactComponent as StopSvg} from '../../../css/imgs/icon-stop.svg';
import {ReactComponent as MoreSvg} from '../../../css/imgs/icon-more.svg';
import {ReactComponent as SelectAllSvg} from '../../../css/imgs/icon-select-all.svg';
import {ReactComponent as RobotSvg} from '../../../css/imgs/icon-robot.svg';

// types = ["submit", "button"]
// status = ["loading","showing","hiding","edit","save","add","delete", null, undefined]
const Button = ({type,status,parentClassName,...rest}) => {

  const buttonRef = useRef(null);

  let params = {...rest};
  let disable = false;
  if(status === "loading" || (params.disabled !== undefined && params.disabled === true)){
    disable = true;
  }
  let disabledClass = (disable) ? " disabled" : ""

  let parentClass = (parentClassName !== undefined) ? parentClassName : "";


  const imageElement = () => {

    let element = (<div></div>);

    if(status === "loading"){
      element = <Loading show="true" size="24px"/>
    }else if(status === "showing"){
      element = <VisibilityOffSvg className="light"/>
    }else if(status === "hiding"){
      element = <VisibilityOnSvg className="light"/>
    }else if(status === "edit"){
      element = <EditSvg className="light"/>
    }else if(status === "save"){
      element = <SaveSvg className="light"/>
    }else if(status === "add"){
      element = <AddSvg className="light"/>
    }else if(status === "delete"){
      element = <DeleteSvg className="light"/>
    }else if(status === "close"){
      element = <CloseSvg className="light"/>
    }else if(status === "continue"){
      element = <ContinueSvg className="light"/>
    }else if(status === "refresh"){
      element = <RefreshSvg className="light"/>
    }else if(status === "search"){
      element = <SearchSvg className="light"/>
    }else if(status === "search-advanced"){
      element = <SearchAdvSvg className="light"/>
    }else if(status === "copy"){
      element = <CopySvg className="light"/>
    }else if(status === "power-off"){
      element = <PowerOffSvg className="light"/>
    }else if(status === "power-on"){
      element = <PowerOnSvg className="light"/>
    }else if(status === "archive"){
      element = <ArchiveSvg className="light"/>
    }else if(status === "check"){
      element = <CheckSvg className="light"/>
    }else if(status === "back"){
      element = <ArrowBackSvg className="light"/>
    }else if(status === "forward"){
      element = <ArrowForwardSvg className="light"/>
    }else if(status === "up"){
      element = <ArrowUpSvg className="light"/>
    }else if(status === "down"){
      element = <ArrowDownSvg className="light"/>
    }else if(status === "filter"){
      element = <FilterSvg className="light"/>
    }else if(status === "upload"){
      element = <UploadSvg className="light"/>
    }else if(status === "download"){
      element = <DownloadSvg className="light"/>
    }else if(status === "preview"){
      element = <PreviewSvg className="light"/>
    }else if(status === "shuffle"){
      element = <ShuffleSvg className="light"/>
    }else if(status === "play"){
      element = <PlaySvg className="light"/>
    }else if(status === "view"){
      element = <VisibilityOnSvg className="light"/>
    }else if(status === "info"){
      element = <InfoSvg className="light"/>
    }else if(status === "stop"){
      element = <StopSvg className="light"/>
    }else if(status === "more"){
      element = <MoreSvg className="light"/>
    }else if(status === "select-all"){
      element = <SelectAllSvg className="light"/>
    }else if(status === "robot"){
      element = <RobotSvg className="light"/>
    }

    
    
    return element;
  }

  let iconSide = "icon-right";
  let leftSideStatuses = ["back"];
  if(leftSideStatuses.indexOf(status) !== -1){
    iconSide = "icon-left";
  }

  return (
    <div className={"button-element"+disabledClass+" "+parentClass+" "+iconSide} >
      <div className="background"></div>
      <div className="button-status-image">
        {imageElement()}
      </div>
      {(type === "submit") 
        ? <input type="submit" ref={buttonRef} disabled={disable} {...rest} />
        : <button ref={buttonRef} type="button" disabled={disable} {...rest} />
      }      
    </div>
  );
};

export default Button;