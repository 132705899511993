// Email related stuff

export const email = {
  verifyEmail: {
    en: "Verify Email",
    ja: "メール確認",
    et: "Kinnita E-mail",
  },
  verifyEmailText: {
    en: "Before you can sign in you'll need to verify your email. You can type in your email below to receive another verification link.",
    ja: "ログインする前に、メールアドレスを確認する必要があります。 下にメールを入力して、別の確認リンクを受け取ることができます。",
    et: "Enne kui sa saad sisse logida, pead kinnitama oma e-maili aadressi. Kirjuta oma e-mail siia uuesti, kui soovid uuesti linki saada."
  },
  email: {
    en: "Email",
    ja: "メール",
    et: "E-maili aadress",
  },
  sendVerificationCode: {
    en: "Send Verification Code",
    ja: "確認コードを送信する",
    et: "Saada kinnituskood",
  },
  emailSentText: {
    en: "Email sent, please check your email. ",
    ja: "メールを送信しました。メールをご確認ください。",
    et: "Kiri saadetud, kontrolli oma e-maile",
  },
  verifyingToken:{
    en: "Verifying Token",
    ja: "トークン確認中",
    et: "Kinnituskood",
  },
  verifyTokenSuccess:{
    en: "Success, Email verified. Logging you in!",
    ja: "成功、メールを確認しました。 ログインしています！",
    et: "Päring õnnestus, e-mail on kinnitatud. Sind logitakse sisse"
  },
  resetPassword: {
    en: "Reset Password",
    ja: "パスワードリセット",
    et: "Vaheta parool",
  },
  resetPasswordText: {
    en: "Forgot your password? Type in your email below to receive a reset link.",
    ja: "パスワードをお忘れですか？ リセットリンクを受け取るには、下にメールを入力してください。",
    et: "Unustasid parooli? Siseta oma aadress, et saada parooli vahetamise link.",
  },
  sendResetPasswordLinkButton: {
    en: "Send Reset Password Link",
    ja: "パスワードリセットリンク送信する",
    et: "Saada parooli vahetamise link",
  },
  sentResetPasswordLink: {
    en: "Reset Password Email sent! Please check your email. ",
    ja: "パスワードのリセットメールを送信しました！ メールを確認してください。",
    et: "Parooli vahetamise link saadetud, kontrolli oma e-maili."
  },
  setNewPassword: {
    en: "Set New Password",
    ja: "新しいパスワードを設定",
    et: "Määra uus parool",
  },
  newPassword: {
    en: "New Password",
    ja: "新しいパスワード",
    et: "Uus parool",
  },
  setPasswordButton: {
    en: "Set Password",
    ja: "パスワード設定する",
    et: "Määra parool",
  },
  setPasswordSuccess: {
    en: "Successfully Set New Password!",
    ja: "新しいパスワードを設定しました！",
    et: "Parooli vahetamine õnnestus",
  },



  verifyEmailChange: {
    en: "Verify Email Change",
    ja: "メールの変更確認",
    et: "Kinnita e-maili aadressi vahetus",
  },
  verifyEmailChangeSuccess: {
    en: "Success! Email Changed. toast, Redirecting you",
    ja: "成功！ メールが変更されました",
    et: "E-maili vahetus õnnestus, suunan ümber",
  },
  tokenInvalid:{
    en: "Either that token is invalid or expired. ",
    ja: "トークンは無効か期限切れです。",
    et: "Kood on aegunud või pole õige",
  },
  verifyEmailChangeErrorText:{
    en: "Looks like something went wrong. Please request a new reset password link. ",
    ja: "問題が発生したようです。 新しいパスワードのリセットリンクをリクエストしてください",
    et: "Midagi läks viltu. Küsi endale uus parooli vahetamise link.",
  },
  verifyEmailChangeErrorListText1:{
    en: "1. If you're not signed in already, sign in. ",
    ja: "1. まだサインインしていない場合は、サインインしてください。",
    et: "1. Kui sa pole veel sisse logitud, logi sisse",
  },
  verifyEmailChangeErrorListText2:{
    en: "2. Go to My account. ",
    ja: "2. マイアカウントに移動します。",
    et: "2. Mine Minu konto-le",
  },
  verifyEmailChangeErrorListText3:{
    en: "3. Click on the edit button next to email. ",
    ja: "3. メールの横にある編集ボタンをクリックします。",
    et: "3. Vajuta Muuda nuppu E-maili juures",
  },
  verifyEmailChangeErrorListText4:{
    en: "4. Type in the email you'd like to change your account to. ",
    ja: "4. アカウントを変更するメールを入力します。",
    et: "4. Siseta e-maili aadress, mida soovid edaspidi kasutada",
  },

}