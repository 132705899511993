import React, { useRef,useEffect } from 'react';
import './hover-div.scss'
import useHover from '../../../utils/use-hover';

const HoverDiv = ({className,hoverCallback,children,...rest}) => {

  const [ref, isHovered] = useHover();
  const callbackRef = useRef(null);
  if(hoverCallback){
    callbackRef.current = hoverCallback
  }

  useEffect(() => {
    if(callbackRef){
      callbackRef.current(ref,isHovered);
    }
  },[callbackRef,ref,isHovered,])

  return (  
    <div ref={ref} className={"hover-div "+className} {...rest}>
      {children}
    </div>
  );

};

export default HoverDiv;