const KlineIntervalEnum = {
  SecondOne: 1*1000,//1000,
  MinuteOne: 1*60*1000,//60000,
  MinuteThree: 3*60*1000,//180000,
  MinuteFive: 5*60*1000,//300000,
  MinuteTen: 10*60*1000,//600000,
  MinuteFifteen: 15*60*1000,//900000,
  MinuteThirty: 30*60*1000,//1800000,
  HourOne: 1*60*60*1000,//3600000
  HourFour: 4*60*60*1000,//14400000
  HourTwelve: 12*60*60*1000,//43200000
  DayOne: 1*24*60*60*1000,//86400000
  DayThree: 3*24*60*60*1000,//259200000
  WeekOne: 1*7*24*60*60*1000,//604800000
  WeekTwo: 2*7*24*60*60*1000,//604800000
  MonthOne: 30*24*60*60*1000,//604800000
}

module.exports = {
  KlineIntervalEnum,
};