export const roboNames = {
  "infinity-icon": {
    "en": "Infinity Icon",
    "ja": "インフィニティ・アイコン"
  },
  "stellar-vanguard": {
    "en": "Stellar Vanguard",
    "ja": "ステラー・ヴァンガード"
  },
  "nexus-strider": {
    "en": "Nexus Strider",
    "ja": "ネクサス・ストライダー"
  },
  "aurora-sentinel": {
    "en": "Aurora Sentinel",
    "ja": "オーロラ・センチネル"
  },
  "chrono-enforcer": {
    "en": "Chrono Enforcer",
    "ja": "クロノ・エンフォーサー"
  },
  "omega-apex": {
    "en": "Omega Apex",
    "ja": "オメガ・エイペックス"
  },
  "nebula-spark": {
    "en": "Nebula Spark",
    "ja": "ネビュラ・スパーク"
  },
  "galactic-fury": {
    "en": "Galactic Fury",
    "ja": "ギャラクティック・フュリー"
  },
  "celestial-echo": {
    "en": "Celestial Echo",
    "ja": "セレスティアル・エコー"
  },
  "titan-forge": {
    "en": "Titan Forge",
    "ja": "タイタン・フォージ"
  },
  "solaris-guardian": {
    "en": "Solaris Guardian",
    "ja": "ソラリス・ガーディアン"
  },
  "pulse-raider": {
    "en": "Pulse Raider",
    "ja": "パルス・レイダー"
  },
  "aero-dynamo": {
    "en": "Aero Dynamo",
    "ja": "エアロ・ダイナモ"
  },
  "xenon-havoc": {
    "en": "Xenon Havoc",
    "ja": "キセノン・ハボック"
  },
  "radiant-expanse": {
    "en": "Radiant Expanse",
    "ja": "レディアント・エクスパンス"
  },
  "zenith-nova": {
    "en": "Zenith Nova",
    "ja": "ゼニス・ノヴァ"
  },
  "serenity-warden": {
    "en": "Serenity Warden",
    "ja": "セレニティ・ウォーデン"
  },
  "vertex-pulse": {
    "en": "Vertex Pulse",
    "ja": "ヴァーテックス・パルス"
  },
  "orion-seraph": {
    "en": "Orion Seraph",
    "ja": "オリオン・セラフ"
  },
  "luna-specter": {
    "en": "Luna Specter",
    "ja": "ルナ・スペクター"
  },
  "astral-blitz": {
    "en": "Astral Blitz",
    "ja": "アストラル・ブリッツ"
  },
  "inferno-ascent": {
    "en": "Inferno Ascent",
    "ja": "インフェルノ・アッセント"
  },
  "mirage-valkyrie": {
    "en": "Mirage Valkyrie",
    "ja": "ミラージュ・ヴァルキリー"
  },
  "quantum-shard": {
    "en": "Quantum Shard",
    "ja": "クォンタム・シャード"
  },
  "cybernetic-dawn": {
    "en": "Cybernetic Dawn",
    "ja": "サイバネティック・ドーン"
  },
  "phoenix-sentinel": {
    "en": "Phoenix Sentinel",
    "ja": "フェニックス・センチネル"
  },
  "hyperion-aegis": {
    "en": "Hyperion Aegis",
    "ja": "ハイペリオン・イージス"
  },
  "echelon-echo": {
    "en": "Echelon Echo",
    "ja": "エシュロン・エコー"
  },
  "vector-luminary": {
    "en": "Vector Luminary",
    "ja": "ベクター・ルミナリー"
  },
  "techno-raider": {
    "en": "Techno Raider",
    "ja": "テクノ・レイダー"
  },
  "astro-flame": {
    "en": "Astro Flame",
    "ja": "アストロ・フレイム"
  },
  "arcane-enigma": {
    "en": "Arcane Enigma",
    "ja": "アーケイン・エニグマ"
  },
  "blade-stryker": {
    "en": "Blade Stryker",
    "ja": "ブレード・ストライカー"
  },
  "pyro-goliath": {
    "en": "Pyro Goliath",
    "ja": "パイロ・ゴライアス"
  },
  "radiant-pinnacle": {
    "en": "Radiant Pinnacle",
    "ja": "レディアント・ピナクル"
  },
  "volt-vanguard": {
    "en": "Volt Vanguard",
    "ja": "ボルト・ヴァンガード"
  },
  "nebula-nova": {
    "en": "Nebula Nova",
    "ja": "ネビュラ・ノヴァ"
  },
  "stellaris-specter": {
    "en": "Stellaris Specter",
    "ja": "ステラリス・スペクター"
  },
  "skyward-asylum": {
    "en": "Skyward Asylum",
    "ja": "スカイワード・アサイラム"
  },
  "nova-nebulon": {
    "en": "Nova Nebulon",
    "ja": "ノヴァ・ネビュロン"
  },
  "infinity-warden": {
    "en": "Infinity Warden",
    "ja": "インフィニティ・ウォーデン"
  },
  "zenith-zenith": {
    "en": "Zenith Zenith",
    "ja": "ゼニス・ゼニス"
  },
  "omega-nebula": {
    "en": "Omega Nebula",
    "ja": "オメガ・ネビュラ"
  },
  "celestial-surge": {
    "en": "Celestial Surge",
    "ja": "セレスティアル・サージ"
  },
  "aurora-atlas": {
    "en": "Aurora Atlas",
    "ja": "オーロラ・アトラス"
  },
  "nexus-nighthawk": {
    "en": "Nexus Nighthawk",
    "ja": "ネクサス・ナイトホーク"
  },
  "pulse-prism": {
    "en": "Pulse Prism",
    "ja": "パルス・プリズム"
  },
  "nebula-nemesis": {
    "en": "Nebula Nemesis",
    "ja": "ネビュラ・ネメシス"
  },
  "solaris-shifter": {
    "en": "Solaris Shifter",
    "ja": "ソラリス・シフター"
  },
  "quantum-quasar": {
    "en": "Quantum Quasar",
    "ja": "クォンタム・クエーサー"
  },
  "xenon-xplorer": {
    "en": "Xenon Xplorer",
    "ja": "キセノン・エクスプローラー"
  },
  "serenity-savant": {
    "en": "Serenity Savant",
    "ja": "セレニティ・サヴァント"
  },
  "aero-argonaut": {
    "en": "Aero Argonaut",
    "ja": "エアロ・アルゴノート"
  },
  "apex-alchemist": {
    "en": "Apex Alchemist",
    "ja": "エイペックス・アルケミスト"
  },
  "luna-luminous": {
    "en": "Luna Luminous",
    "ja": "ルナ・ルミナス"
  },
  "astral-automaton": {
    "en": "Astral Automaton",
    "ja": "アストラル・オートマトン"
  },
  "orion-octane": {
    "en": "Orion Octane",
    "ja": "オリオン・オクタン"
  },
  "vertex-vanguard": {
    "en": "Vertex Vanguard",
    "ja": "ヴァーテックス・ヴァンガード"
  },
  "radiant-ronin": {
    "en": "Radiant Ronin",
    "ja": "レディアント・ローニン"
  },
  "inferno-ignition": {
    "en": "Inferno Ignition",
    "ja": "インフェルノ・イグニッション"
  },
  "mirage-maven": {
    "en": "Mirage Maven",
    "ja": "ミラージュ・メイヴン"
  },
  "techno-tinkerer": {
    "en": "Techno Tinkerer",
    "ja": "テクノ・ティンカラー"
  },
  "arcane-arbiter": {
    "en": "Arcane Arbiter",
    "ja": "アーケイン・アービター"
  },
  "phoenix-paragon": {
    "en": "Phoenix Paragon",
    "ja": "フェニックス・パラゴン"
  },
  "echelon-epoch": {
    "en": "Echelon Epoch",
    "ja": "エシュロン・エポック"
  },
  "vector-vagabond": {
    "en": "Vector Vagabond",
    "ja": "ベクター・ヴァガボンド"
  },
  "pyro-paladin": {
    "en": "Pyro Paladin",
    "ja": "パイロ・パラディン"
  },
  "astro-adept": {
    "en": "Astro Adept",
    "ja": "アストロ・アデプト"
  },
  "blade-byte": {
    "en": "Blade Byte",
    "ja": "ブレード・バイト"
  },
  "nova-nomad": {
    "en": "Nova Nomad",
    "ja": "ノヴァ・ノマド"
  },
  "infinity-instigator": {
    "en": "Infinity Instigator",
    "ja": "インフィニティ・インスティゲーター"
  }
}
