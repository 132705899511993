import React, {useState, useEffect, useRef} from 'react';
import './carousel-header.scss';

import useWindowResize from '../../../utils/use-window-resize'

const CarouselHeader = ({slideTo, className, forceResize, children, }) => {

  const [width, height] = useWindowResize();

  let [underlineStyle, setUnderlineStyle] = useState({
    left: 0,
    width: "10px",
  });
  let activeRef = useRef(null);
  let index = slideTo - 1;
  let newChildren = [];

  children.map((d,i) => {
    let addClass = (d.props.className !== undefined) ? d.props.className: "";
    let newProps = { 
      key: i 
    };

    if(i === index){
      addClass += " active";
      newProps.ref = activeRef;
    }
    newProps.className = addClass.trim();

    let newChild = React.cloneElement(d, newProps);

    newChildren.push(newChild);
    return false;
  });

  useEffect(() => {
    if(activeRef.current !== null){
      setUnderlineStyle({
        left: activeRef.current.offsetLeft,
        width: activeRef.current.scrollWidth,
      });
    }
  },[slideTo, forceResize, width, height]);


  return (
    <div className={"carousel-header "+className}>
      <div className="items hlo">
        {newChildren}
      </div>
      <div className='carousel-header-underline-container'>
        <span className="carousel-header-underline" style={underlineStyle}></span>
      </div>
    </div>
  );
};

export default CarouselHeader;