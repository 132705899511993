import React,{useState,useEffect} from 'react';
import './sign-in.scss';
import Config from '../../../../config/config';
import { Link, Redirect } from 'react-router-dom';
import useUserState from '../../../../store/user';
import Button from '../../../elements/button/button';
import PasswordInput from '../../../elements/password-input/password-input';
import {request,unSubRequest} from '../../../../utils/request'
import {toast} from 'react-toastify';
import Recaptcha from 'react-google-recaptcha';
import * as NumberUtil from '../../../../utils/number';
import {t} from '../../../../translations/i18n';

const SignIn = ({successCallback}) => {

  const [recaptchaKey,setRecaptchaKey] = useState(1);
  const [recaptchaValue,setRecaptchaValue] = useState("");
  const {user,loginUser} = useUserState();
  const [usernameOrEmail,setUsernameOrEmail] = useState("");
  const [password,setPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [buttonStatus,setButtonStatus] = useState("");
  const [errorMessage,setErrorMessage] = useState("");
  const [successMessage,setSuccessMessage] = useState("");
  const [redirect,setRedirect] = useState(null);

  useEffect(() => {
    unSubRequest("sign-in");
  },[]);

  useEffect(() => {
    setButtonStatus((loading)?"loading":"");
  },[loading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(usernameOrEmail !== "" && password !== ""){
      const data = {
        usernameOrEmail,
        password,
        recaptchaValue,
      };
      if(localStorage.getItem("device")){
        data.deviceToken = localStorage.device;
      }

      setLoading(true);
      setErrorMessage("");
      request("sign-in","/login","POST", data, {
        then: function(res){
          let results = res.data.res;
          if(results.totpRequired && !results.totpPassed){
            let newRedirect = "/totp?token="+results.totpLoginToken;;
            setRedirect(newRedirect);
          }else if(results.deviceConfirmationRequired){
            let newRedirect = "/new-device?token="+results.deviceLoginToken;;
            setRedirect(newRedirect);
          }else if(results.user && results.auth){
            toast.success(t('entry.logInSuccess'));      
            let holdUser = results.user;
            holdUser.auth = results.auth;
            loginUser(holdUser);
          }else{
            toast.error("Error: Auth Tokens not returned. Please contact us if this happens.");
          }
        },
        catch: function(err){
          if(err.verifyEmail !== undefined){
            setErrorMessage(<span>Please verify your email. <Link to="/verify-email">To request a new email verification code, click here</Link></span>);
          }else{
            toast.error(err.message);
          }
        },
        finally: function(){
          let randInt = NumberUtil.getRandomInt(2,99999999);
          setRecaptchaKey(randInt);
          setRecaptchaValue("");
          setLoading(false);
        }
      });

    }
  }

  useEffect(() => {
    if(user !== null){
      setSuccessMessage(t('entry.logInSuccess'));      
      if(successCallback !== undefined){
        successCallback();
      }
    }
  },[user,successCallback])


  if(redirect){
    return <Redirect to={redirect}/>
  }

  return (
    <div className="sign-in">
      {(successMessage !== "") ? <ul className="success"><li>{successMessage}</li></ul> : ""}
      {(errorMessage !== "") ? <ul className="errors"><li>{errorMessage}</li></ul> : ""}

      <form onSubmit={handleSubmit}>
        <label>{t('entry.usernameOrEmail')}</label>
        <input disabled={loading} type="text" placeholder={t('entry.usernameOrEmail')} defaultValue={usernameOrEmail} onChange={(e) => {setUsernameOrEmail(e.target.value)}}/>
        <label>{t('entry.usernameOrEmail')}</label>
        <PasswordInput disabled={loading} placeholder={t('entry.password')} defaultValue={password} onChange={(e) => {setPassword(e.target.value)}}/>
        <Recaptcha key={recaptchaKey}
            className="recaptcha" sitekey={Config.Keys.RecaptchaPubKey} 
            onChange={(value) => {setRecaptchaValue(value)}}
            onExpired={() => {setRecaptchaValue("")}}/>
        <Button disabled={loading} type="submit" status={buttonStatus} value={t('entry.signIn')}/>
      </form>
      <Link className="reset-password" to="/reset-password">{t('entry.troubleLoggingIn')}</Link>
    </div>
  );
};

export default SignIn;