const SnsTypeEnum = {
  Google: 1,
  Facebook: 2,
};

export const SnsTypeTextEnum = {
  Google: "google",
  Facebook: "facebook",
};

export default SnsTypeEnum;