import React, { useEffect } from 'react';
import './navi-notifications.scss';

import * as DateUtil from '../../utils/date';
import {request,unSubRequest} from '../../utils/request';
import { toast } from 'react-toastify';
import useUserState from '../../store/user';
import { Link } from 'react-router-dom';
import {t} from '../../translations/i18n';

const NaviNotifications = ({notifs,open}) => {

  const {user} = useUserState();

  useEffect(() => {
    return () => {
      unSubRequest("mark-all-read");
    }
  },[])

  if(open && notifs && notifs.unseen){
    request("mark-all-read","/mark-all-notifications-read","POST", {}, {
      then: function(res){},
      catch: function(err){toast.error(err.message);},
      finally: function(){}
    });
  }

  return (
    <div className={"navi-notifications"}>
      {(notifs && notifs.notifs && notifs.notifs.length)?
        notifs.notifs.map((v,i) => {
          return(
            <div className={"notif-item "+((v.type)?v.type:"") + ((v.seen)?"":" unseen")} key={i}>
              <div className="notif-id">{(v.id)?v.id:""}</div> 
              <div className="notif-header">{(v.header)?v.header:""}</div> 
              <div className="notif-message">{(v.message)?v.message:""}</div> 
              <div className="notif-date">{DateUtil.dateTimeSinceWithTime(new Date(v.createdDate))}</div>
            </div>
          )
        })
      :(user)?<div className="no-new-notifs"><span>No new notifications</span></div>
      :
      <div className="not-signed-in">
        <Link className='button' to="/create-account">{t('entry.createAccount')}</Link>
        <span>{t('entry.or')}</span>
        <Link className='button' to="/login">{t('entry.signIn')}</Link> 
      </div>}
    </div>
  );

};

export default NaviNotifications;