export const time = {
  "seconds-ago": {
    "en": "{{value}} Seconds Ago",
    "ja": "{{value}} 秒前"
  },
  "second-ago": {
    "en": "{{value}} Second Ago",
    "ja": "{{value}} 秒前"
  },
  "minutes-ago": {
    "en": "{{value}} Minutes Ago",
    "ja": "{{value}} 分前"
  },
  "minute-ago": {
    "en": "{{value}} Minute Ago",
    "ja": "{{value}} 分前"
  },
  "hours-ago": {
    "en": "{{value}} Hours Ago",
    "ja": "{{value}} 時間前"
  },
  "hour-ago": {
    "en": "{{value}} Hour Ago",
    "ja": "{{value}} 時間前"
  },
  "days-ago": {
    "en": "{{value}} Days Ago",
    "ja": "{{value}} 日前"
  },
  "day-ago": {  
    "en": "{{value}} Day Ago",
    "ja": "{{value}} 日前"
  },
  "now": {
    "en": "Now",
    "ja": "今"
  },
}
