import React from 'react';
import './center-div.scss';

const CenterDiv = ({sidePadding,children}) => {

  let newSidePadding = (sidePadding !== undefined) ? sidePadding : "0px"

  let customPadding = {
    padding: "0px "+newSidePadding
  }
  
  return (
    <div className="center-div-outer">
      <div className="center-div-middle" style={customPadding}>
        {children}
      </div>
    </div>
  );
};

export default CenterDiv;