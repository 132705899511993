import React, { useState,useEffect } from 'react';
import './uninstall.scss';
// import {Link} from 'react-router-dom';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
// import useUserState from '../../../store/user';
import Button from '../../elements/button/button';

const Uninstall = ({type}) => {

  // const {user} = useUserState();
  const [loading,setLoading] = useState(false);
  const [text,setText] = useState("");

  useEffect(() => {
    return () => {
      unSubRequest("feedback");
    }
  },[]);

  const handleSubmit = (e) => {
    e.preventDefault();
    send();
  } 

  const send = () => {
    let data = {
      text,
      type:"feedback",
    }
    setLoading(true);
    request("feedback","/feedback","POST", data, {
      then: function(res){ 
        toast.success("Feedback Received! Thank you.")
        setText("");
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  // if(!user){
  //   return(
  //     <div className="uninstall-comp">
  //       <p>Please <Link to="/login">Login</Link> before leaving feedback. </p>
  //       <p>If you're having trouble logging in you can <Link to="/contact">Contact Us</Link></p>
  //     </div>
  //   )
  // } 

  return (
    <div className="uninstall-comp">
      <h1>Uninstall</h1>
      <p>Sorry to see you go, could you write why you've uninstalled?</p>
      {/* <div className="feedback-content">
        <form onSubmit={handleSubmit}>
          <div>
            <textarea 
              disabled={loading}
              onChange={(e) => {
              setText(e.target.value)
            }} value={text}></textarea>
          </div>
          <Button type="submit" status={(loading)?"loading":""} value={"Send"}/>
        </form>
      </div> */}
    </div>
  );

};

export default Uninstall;