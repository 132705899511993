

const Settings = {
  TopNavi: true,
  SideNavi: false,
  StickyNavi: false,
  Footer: true,
};


export default Settings;
