import React,{useEffect,useCallback} from 'react';
import './modal.scss'
import CenterDiv from '../center-div/center-div';
import {ReactComponent as CloseSVG} from '../../../css/imgs/icon-close.svg';

const Modal = ({ className, handleClose, show, confirm, confirmText, children }) => {
  const showHideClassname = show ? " show-modal" : " hide-modal";

  const keydownListenerCallback = useCallback(
    (e) => { 
      if(e.keyCode === 27) {// esc 
        // handleClose();
        localHandleClose(handleClose);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[handleClose],
  );

  useEffect(() =>{
    document.addEventListener("keydown", keydownListenerCallback, false);
    return () => {
      document.removeEventListener("keydown", keydownListenerCallback, false);
    }
  },[keydownListenerCallback]);

  useEffect(() =>{
    if(show){
      document.body.style.overflow = "hidden";
    }else{
      document.body.style.overflow = "";
    }
  },[show]);

  const localHandleClose = (handleClose) => {
    if(confirm){
      let txt = "Do you really want to close the modal?";
      if(confirmText) txt = confirmText;
      if (!window.confirm(txt)) return;
    }
    handleClose();
  }

  return(
    <div className={"modal"+showHideClassname}>
      <div onClick={() =>{localHandleClose(handleClose)}} className="modal-gray-screen"></div>
        <CenterDiv>
          <div className={"modal-main "+className}>
            <div onClick={() =>{localHandleClose(handleClose)}} className={'modal-close-button'}>
              <CloseSVG />
            </div>
            <div className="modal-content">
              {children}
            </div>
          </div>
        </CenterDiv>
    </div>
  )
};

export default Modal;