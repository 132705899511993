import { clone } from '../utils/clone';

import { common } from './views/common';
import { email } from './views/email';
import { faq } from './views/faq';
import { myAccount } from './views/my-account';
import { navi } from './views/navi';
import { payment } from './views/payment';
import { roboNames } from './data/robo-names';
import { roboTracks } from './data/robo-tracks';
import { roboRally } from './views/robo-rally';
import {time} from './data/time';
import { entry } from './views/entry';

/**
 * Converts our message structure to only contain one locale's text. This is required by our i18n library
 *
 * @param {Object} obj A key/value object containing several localisation texts
 * @param {String} locale The key of the locale text we want in the output
 */
const convertToSingularLocale = (obj, locale ) => {
  let item = clone(obj);

  for (let key in item) {
    if (key === locale) {
      // this key is a locale, return it
      return item[key];
    } else if (typeof item[key] === 'object') {
      item[key] = convertToSingularLocale(item[key], locale)
    }
  }

  return item;
}

const allTranslations = {
  general: {
    languageAbbreviation: {
      de: "de",
      es: "es",
      en: "en",
      ja: "ja",
      fr: "fr",
      zh: "zh",
    },
  },
  common: common,
  email: email,
  faq: faq,
  myAccount: myAccount,
  navi: navi,
  payment: payment,
  roboNames: roboNames,
  roboTracks: roboTracks,
  time: time,
  roboRally: roboRally,
  entry: entry,
}


// export const german = convertToSingularLocale(translations, 'de')
// export const spanish = convertToSingularLocale(translations, 'es')
export const english = convertToSingularLocale(allTranslations, 'en')
export const japanese = convertToSingularLocale(allTranslations, 'ja')
// export const french = convertToSingularLocale(translations, 'fr')
// export const chinese = convertToSingularLocale(translations, 'zh')


export const translations = {
  en: english,
  ja: japanese,
}

export const availableLanguages = [
  { name: "English", nativeName:"English", code: "en" },
  { name: "Japanese", nativeName:"日本語", code: "ja" },
]