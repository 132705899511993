import React from 'react';
import './navi-mobile.scss';

import NaviLogo from '../common/logo/navi-logo';
import useHeaderState from '../../store/header';
import {ReactComponent as MenuSvg} from '../../css/imgs/icon-menu.svg';
import {ReactComponent as NotificationsSvg} from '../../css/imgs/icon-notifications.svg';

const NaviMobile = ({unSeenNotifs}) => {

  const {toggleMenu, toggleNotifications, header} = useHeaderState();
  const menuClass = (header.menuOpen) ? " menuOpen" : " menuClose";
  const notificationsClass = (header.menuOpen) ? " notifsOpen" : " notifsClose";

  let unSeenNotifsLocal = unSeenNotifs && parseInt(unSeenNotifs) > 0?unSeenNotifs:0;

  return (
    <div className={"navi-mobile"+menuClass+notificationsClass}>
      <button className="menu button" onClick={toggleMenu}><MenuSvg /></button>
      <div className="logo-container">
        <NaviLogo/>
      </div>
      <button className="notifications button" onClick={toggleNotifications}>
        <NotificationsSvg />
        <span className={"badge "+((unSeenNotifsLocal > 0)?"show":"hide")}>{unSeenNotifsLocal}</span>
      </button>
    </div>
  );

};

export default NaviMobile;
