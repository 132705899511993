
import { setGlobal, } from 'reactn';
import * as stringUtil from '../utils/string';

const initStore = () => {
  setGlobal({
    header: {menuOpen:false},
    user: (localStorage.getItem('user') !== null) ? stringUtil.tryJSONParse(localStorage.user) : null,
    snsInfo: null,
    modal: "",
    socket: null,
    socketClientId: null,
    restartSocket: false,
    socketMessages: {},
    globalArray: [],
  });
};

export default initStore;



// import {createGlobalState} from 'react-hooks-global-state';
// import * as stringUtil from '../utils/string';


// const {setGlobalState, useGlobalState} = createGlobalState({
//   header: {menuOpen:false},
//   user: (localStorage.getItem('user') !== null) ? stringUtil.tryJSONParse(localStorage.user) : null,
//   snsInfo: null,
//   modal: "",
//   socket: null,
//   socketClientId: null,
//   restartSocket: false,
//   socketMessages: {},
//   globalArray: [],
// })

// export {setGlobalState,useGlobalState}

