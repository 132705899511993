import React from 'react';
import './payment.scss';
import Config from '../../../config/config';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from './payment-form';

const stripePromise = loadStripe(Config.Keys.StripePublicKey);

const Payment = ({
        formSubmitedCallback,
        successCallback,
        errorCallback,
        hideButton,
        passRef,
        hide
      }) => {

  return (
    <div className={"payment"+((hide)?" hide":"")}>
      
      <Elements stripe={stripePromise}>
        <PaymentForm 
          passRef={passRef}
          formSubmitedCallback={formSubmitedCallback}
          successCallback={successCallback} 
          errorCallback={errorCallback} 
          hideButton={hideButton}
          />
      </Elements>
    </div>
  );
};

export default Payment;