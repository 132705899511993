import React,{useState} from 'react';
import './navi-main.scss';
import Settigns from '../../config/settings';
import NaviLogo from '../common/logo/navi-logo';
import NaviBody from './navi-body';
import NaviFooter from './navi-footer';

import Settings from '../../config/settings';

const NaviMain = ({menuClass,toggleWidth,setToggleWidth}) => {

  const [activeRef,setActiveRef] = useState(null);
  const [top,setTop] = useState(0);
  const [display,setDisplay] = useState("test");
  const [desc,setDesc] = useState("test");

  const hoverCallback = (ref,isHover,display,desc,) => {
    if(!Settings.SideNavi) return;
 
    let newTop = top;
    let newRef = null;
    if(isHover){
      newRef = ref;
    }

    if(ref){
      let rect = ref.current.getBoundingClientRect();
      newTop = rect.top
    }

    setDisplay((display)?display:"");
    setDesc((desc)?desc:"");
    setTop(newTop);
    setActiveRef(newRef);
  }

  let itemStyle = {
    top: top,
  }

  let settingsClass = (Settigns.TopNavi)?" top-navi":" side-navi"

  return (
    <div className={"navi-main"+menuClass+((toggleWidth)?" toggle-width-active":"")+settingsClass}>
      <div className={"width-toggle"} onClick={() => {setToggleWidth(!toggleWidth)}}></div>
      <NaviLogo options={"small"} />
      <NaviBody hoverCallback={hoverCallback} />
      <NaviFooter hoverCallback={hoverCallback} />
      <div className={"display-item "+((activeRef && toggleWidth)?"":"hide")} style={itemStyle}>
        <div className="detail">
          <div className="display">{display}</div>
          <div className="desc">{desc}</div>
        </div>
      </div>
    </div>
  );

};

export default NaviMain;