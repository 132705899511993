import React,{useState,useEffect,} from 'react';
import { NavLink } from 'react-router-dom';
import './navi-footer.scss';
import HoverDiv from '../elements/hover-div/hover-div';
import { t } from "../../translations/i18n";

import useUserState from '../../store/user';
import useHeaderState from '../../store/header';

import {ReactComponent as LanguageSvg} from '../../css/imgs/icon-language.svg';
import {ReactComponent as LoginSvg} from '../../css/imgs/icon-login.svg';
import {ReactComponent as LogoutSvg} from '../../css/imgs/icon-logout.svg';
import {ReactComponent as PersonSvg} from '../../css/imgs/icon-person.svg';
import {ReactComponent as PersonAddSvg} from '../../css/imgs/icon-person-add.svg';
import {ReactComponent as NotificationsSvg} from '../../css/imgs/icon-notifications.svg';
import {ReactComponent as CloseSvg} from '../../css/imgs/icon-close.svg';
import useModalState,{ModalEnum} from '../../store/modal';
import NaviNotifications from './navi-notifications';
import Config from '../../config/config';

const NaviFooter = ({hoverCallback, notifs}) => {

  const {user,logoutUser} = useUserState();
  const {setActiveModal} = useModalState();

  // console.log("user",user);

  const [showAccountMenu,setShowAccountMenu] = useState(false);
  const {toggleNotifications, header} = useHeaderState();

  useEffect(() => {
    const ignoreClickIds = ["account-username","account-menu"];
    const listener = (e) => {
      if(ignoreClickIds.indexOf(e.target.id) === -1){
        setShowAccountMenu(false);
      }
    }
    if(showAccountMenu){
      window.addEventListener("click", listener);
    }
    return () => {
      if(showAccountMenu){
        window.removeEventListener("click", listener);
      }
    }
  },[showAccountMenu]);

  let unSeenNotifs = (notifs && notifs.unseen)?parseInt(notifs.unseen):0;

  let displayText = t("navi.language");
  return (
    <div className={"navi-footer"}>
      <HoverDiv className="navi-footer-button"  hoverCallback={(ref,isHovered) => hoverCallback(ref,isHovered,displayText)} 
        onClick={() => {setActiveModal(ModalEnum.Language)}}>        
        <span className="icon"><LanguageSvg/></span>
        <span className="text">{displayText}</span>
      </HoverDiv>
      <div className="account-related">
        {(user)?
          <div id="account-username" className="block hlo" onClick={() => {setShowAccountMenu(!showAccountMenu)}}>
            <div className="icon">
              {(user && user.imageUid)?
                <img src={Config.Common.ApiBaseUrl+"/f/"+user.imageUid} alt="user-icon"/>
                :<PersonSvg />
              }
            </div>
            <div className="text">{user.username}</div>
          </div>
        :null}
        {(user)?
          <div id="account-notifs" className="block hlo" onClick={toggleNotifications}>
            <div className="icon">
              <NotificationsSvg/>
              <span className={"badge "+((unSeenNotifs > 0)?"show":"hide")}>{unSeenNotifs}</span>
            </div>
          </div>
        :null}
        {(!user)?
          <NavLink className="block" to="/login">
            <div className="icon"><LoginSvg/></div>
            <div className="text">{t("navi.login")}</div>
          </NavLink>
        :null}
        {(!user)?
          <NavLink className="block" to="/create-account">
            <div className="icon"><PersonAddSvg/></div>
            <div className="text">{t("navi.createAccount")}</div>
          </NavLink>
        :null}
      </div>

      <div id="account-menu" className={"account-menu hlo"+((showAccountMenu)?"":" hide")}>
        <NavLink className="block" to="/my-account">
          <div className="icon">
            {(user && user.imageUid)?
              <img src={Config.Common.ApiBaseUrl+"/f/"+user.imageUid} alt="user-icon"/>
              :<PersonSvg/>
            }
          </div>
          <div className="text">
            <div className="display">{t("navi.myAccount")}</div>
            <div className="desc">{(user)?user.username:""}</div>
          </div>
        </NavLink>
        <div className="block" onClick={logoutUser} >
          <div className="icon"><LogoutSvg /></div>
          <div className="text">
            <div className="display">{t("navi.logout")}</div>
          </div>
        </div>
      </div>

      <div id="notifications-window" className={"notifications-window"+((header.notificationsOpen)?" show":"")}>
        <span className="close-button" onClick={toggleNotifications}><CloseSvg /></span>
        <NaviNotifications notifs={notifs} open={header.notificationsOpen} />
      </div>

    </div>
  );

};

export default NaviFooter;