import React from 'react';
import './checkbox.scss'

const Checkbox = ({className,inLabel,value,setValue,...rest}) => {

  inLabel = (inLabel === undefined)?true:inLabel

  return (
    <div 
      className={"checkbox-element "+((className)?className:"")+" "+((value)?"checked":"")} 
      onClick={() => {if(!inLabel) setValue(!value) }}
      >
      <div className="check"></div>
      <input hidden type="checkbox" value={value} onChange={(e) => {
        if(inLabel) setValue(!value)
      }} {...rest} />      
    </div>
  );
};

export default Checkbox;