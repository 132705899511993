import React from 'react';
import './test.scss';

const Test = () => {
  return (
    <div className="test">
      <h3>Test Component</h3>

    </div>
  );
};

export default Test;