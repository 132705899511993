export const entry = {
  signIn: {
    en: "Sign In",
    ja: "サインイン"
  },
  createAccount: {
    en: "Create Account",
    ja: "アカウント登録",
  },
  usernameOrEmail: {
    en: 'Username or Email',
    ja: 'ユーザー名またはメール',
  },
  username: {
    en: 'Username',
    ja: 'ユーザー名',
  },
  email: {
    en: 'Email',
    ja: 'メール',
  },
  password: {
    en: "Password",
    ja: "パスワード"
  },
  signUpDisclaimer: {
    en: 'By clicking "Create Account" you agree to our <0>Terms of Service</0> and <1>Privacy Policy</1>.',
    ja: '[アカウント登録]をクリックすると、<0>利用規約</0>と<1>プライバシーポリシー</1>に同意したことになります。',
  },
  signUpCheckDisclaimer: {
    en: "Please confirm that you've reviewed the terms of use.",
    ja: '利用規約を確認したことを確認してください。',
  },
  termsOfServiceAgreement: {
    en: "I have read and agree to {{appName}}’s {{tosLink}}",
    ja: "{{appName}} の {{tosLink}} を読み、同意します",
  },
  termsOfService:{
    en: "Terms of Service",
    ja: "利用規約",
  },
  troubleLoggingIn: {
    en: "Trouble Logging In?",
    ja: "パスワーどお忘れですか？",
  },
  or: {
    en: "or",
    ja: "または",
  },
  signupSuccessNice:{
    en: "Success! Registered your account. Please check your email. ",
    ja: "成功! アカウント登録できました. メールを確認してください。 ",
  },
  signupSuccessCheckJunkMail:{
    en: "Check your junk/spam mail if it's not in your inbox. ",
    ja: "受信トレイに入ってなかった場合は迷惑メールでも確認してください。",
  },
  signupSuccessDidntReceiveEmail:{
    en: "If you didn't receive an email. <0>Resend Verification Email</0>",
    ja: "メールもらってない場合。<0>確認メールを再送する</0>",
  },
  signupSuccessAlreadyHaveAccount:{
    en: "If you think you may have an account with us already. <0>Reset Password</0>",
    ja: "すでにアカウントあるかもと言う方はこちら。<0>パスワードを再設定する</0>",
  },
  snsLoading:{
    en: "Loading {{sns}} resources",
    ja: "{{sns}}データ読み込み中",
  },
  snsFailedToLoad:{
    en: "Failed to Load {{sns}} resources",
    ja: "{{sns}}データ読み込み失敗した",
  },
  snsConnect:{
    en: "Connect with {{sns}}",
    ja: "{{sns}}で続く",
  },
  snsLink:{
    en: "Link account with {{sns}}",
    ja: "{{sns}}と繋ぐ",
  },
  snsUnlink:{
    en: "Unlink Account",
    ja: "アカウントリンク解除する",
  },
  snsSetPass:{
    en: "Please set a password before unlinking your SNS account. ",
    ja: "パスワードを設定してからSNSアカウントリンク解除できます。",
  },
  snsUnlinkSuccess: {
    en: "Success, unlinked your account! ",
    ja: "成功、SNSアカウントリンク解除しました。",
  },
  logInSuccess: {
    en: "Success! Logging you in 😎",
    ja: "成功！ ログインしています 😎",
  },
  pleaseVerifyYourEmail: {
    en: "Please verify your email.",
    ja: "メールアドレスを確認してください。",
  },
  pleaseVerifyYourEmailLinkText: {
    en: "To request a new email verification code, click here",
    ja: "新しいメール確認コードをリクエストするには、ここをクリックしてください",
  },  

}