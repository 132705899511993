import React from 'react';
import './welcome.scss';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Welcome = () => {

  return (
    <div className="welcome-comp">
      <h1>Welcome</h1>
      <p>Thanks so much for installing</p>
    </div>
  );

};

export default Welcome;