import React from 'react';
import { NavLink } from 'react-router-dom';
import './navi-body.scss';

import HoverDiv from '../elements/hover-div/hover-div';
import Config from '../../config/config';

import {ReactComponent as InfoSvg} from '../../css/imgs/icon-info.svg';
import {ReactComponent as LoginSvg} from '../../css/imgs/icon-login.svg';
import {ReactComponent as LogoutSvg} from '../../css/imgs/icon-logout.svg';
import {ReactComponent as PersonSvg} from '../../css/imgs/icon-person.svg';

import { t } from "../../translations/i18n";
import naviRoutes from './navi-routes';
import useUserState from '../../store/user';

const NaviBody = ({hoverCallback}) => {

  const {user,logoutUser} = useUserState();

  let passRoutes = [];
  for (let i = 0; i < naviRoutes.length; i++) {
    const naviRoute = naviRoutes[i];
    let add = true
    if(naviRoute.minStatus !== undefined){
      if(user === null || user.status < naviRoute.minStatus){
        add = false;
      }
    }

    if(naviRoute.userIds !== undefined){
      if(user === null || naviRoute.userIds.indexOf(user.id) === -1){
        add = false;
      }
    }

    if(add){
      passRoutes.push(naviRoute);
    }
  }

  return (
    <div className={"navi-body"}>
      <div className="list">
        {passRoutes.map((v,i) => {
          return (
            <HoverDiv className="list-item" hoverCallback={(ref,isHovered) => hoverCallback(ref,isHovered,v.display,v.desc)} key={i} >
              <NavLink to={v.path} className={v.name}>
                <div className="icon">{(v.icon !== undefined)? v.icon: <InfoSvg />}</div>
                <div className="detail">
                  <div className="display">{v.display}</div>
                  {(v.desc)?<div className="desc">{v.desc}</div>:""}
                </div>
              </NavLink>
            </HoverDiv>
          )
        })}
      </div>
      <div className="line"></div>
      <div className="list user-sign">
        {(user) ? 
          <HoverDiv className="user-options list-item" hoverCallback={(ref,isHovered) => hoverCallback(ref,isHovered,t("navi.myAccount"),user.username)}>
            <NavLink className="my-account" to="/my-account">
              <div className="icon">
                {(user && user.imageUid)?
                  <img src={Config.Common.ApiBaseUrl+"/f/"+user.imageUid} alt="user-icon"/>
                  :<PersonSvg />
                }
              </div>
              <div className="detail">
                <div className="display">{t("navi.myAccount")}</div>
                <div className="desc">{user.username}</div>
              </div>
            </NavLink>
          </HoverDiv>
          :
          <HoverDiv className="list-item" hoverCallback={(ref,isHovered) => hoverCallback(ref,isHovered,t("navi.createAccount"))}>
            <NavLink className="create-account" to="/create-account">
              <div className="icon"><PersonSvg/></div>
              <div className="detail">
                <div className="display">{t("navi.createAccount")}</div>
              </div>
            </NavLink>
          </HoverDiv>
        }
        {(user) ? 
          <HoverDiv className="list-item" hoverCallback={(ref,isHovered) => hoverCallback(ref,isHovered,t("navi.logout"))}>
            <div className="logout" onClick={logoutUser} >
              <div className="icon"><LogoutSvg /></div>
              <div className="detail">
                <div className="display">{t("navi.logout")}</div>
              </div>
            </div>
          </HoverDiv>
          :
          <HoverDiv className="list-item" hoverCallback={(ref,isHovered) => hoverCallback(ref,isHovered,t("navi.login"))}>
            <NavLink className="create-account" to="/login">
              <div className="icon"><LoginSvg /></div>
              <div className="detail">
                <div className="display">{t("navi.login")}</div>
              </div>
            </NavLink>
          </HoverDiv>
        }  
      </div>
    </div>
  );

};

export default NaviBody;