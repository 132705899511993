import React from 'react';
import Entry from './entry';
import './entry.scss';

const EntryLogin = () => {

  return (
    <div className="entry-login">
      <Entry option="login"/>
    </div>
  )
};

export default EntryLogin;