import React from 'react';
import './not-found.scss';

const NotFound = () => {

  return (
    <div className="not-found">
      <h1>404 Not Found</h1>
    </div>
  );

};

export default NotFound;