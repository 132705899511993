import React from 'react';
import './footer.scss';

import Config from '../../config/config';
import { Link } from 'react-router-dom';
import Logo from '../common/logo/logo';
import { t } from "../../translations/i18n";

import {ReactComponent as LanguageSvg} from '../../css/imgs/icon-language.svg';
import {ReactComponent as FacebookSvg} from '../../css/imgs/sns-icon-facebook.svg';
import {ReactComponent as RedditSvg} from '../../css/imgs/sns-icon-reddit.svg';
import {ReactComponent as TwitterSvg} from '../../css/imgs/sns-icon-twitter.svg';
import {ReactComponent as YoutubeSvg} from '../../css/imgs/sns-icon-youtube.svg';
import {ReactComponent as WarningSvg} from '../../css/imgs/icon-warning.svg';

import useModalState,{ModalEnum} from '../../store/modal';

const Footer = () => {

  const year = new Date().getFullYear();

  const {setActiveModal} = useModalState();

  const communityLinks = [
    {icon: <RedditSvg/>, link: "https://google.com",},
    {icon: <FacebookSvg/>, link: "https://google.com",},
    {icon: <TwitterSvg/>, link: "https://google.com",},
    {icon: <YoutubeSvg/>, link: "https://google.com",},
  ];

  return (
    <div className="footer">
      <div className="footer-sub">
        <div className="footer-logo">
          <Logo options={"white"}/>
        </div>

        {(Config.Sandbox)?
          <div className='sandbox-note'>
            <div className='sandbox-note-header'>
              <WarningSvg />
              <div className='text'>Sandbox Environment</div>
              <WarningSvg />
            </div>
            <p>This is the Sandbox Environment. It's meant for merchants & developers to test.</p>
            <p><a href={Config.Common.LiveFrontEndUrl+"/login"}>Regular Users should use the live version. Click here to be redirected there. </a></p>
          </div>
        :null}

        <div className="footer-blocks">
          <div className="block">
            <div className="header">About</div>
            <div className="links">
              {/* <a target="_blank" rel="noopener noreferrer" href={Config.Common.CompanyLink}>About Us</a>               */}
              <Link to="/terms-of-use">Terms of Use</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
          {/* <div className="block services">
            <div className="header">Services</div>
            <div className="links">
              <Link to="/dashboard">Dashboard</Link>
              <Link to="/pricing">Pricing</Link>
              <Link to="/my-account#api-keys">Api</Link>
              <a target="_blank" href="https://docs.adbegone.com">Api Docs</a>
            </div>
          </div> */}
          <div className="block">
            <div className="header">Support</div>
            <div className="links">
              <Link to="/how-to-use">How To Use</Link>
              <Link to="/help">Help Center</Link>
              <Link to="/support">Support</Link>
              <Link to="/feedback">Submit Feedback</Link>
              <Link to="/bug-report">Report a Bug</Link>
              {/* <a target="_blank" href="https://status.adbegone.com">Site Health Status</a> */}
            </div>
          </div>
          <div className="block community">
            <div className="header">Community</div>
            <div className="links">
              {
                communityLinks.map((v,i) => {
                  return(
                    <a key={i} target="_blank" rel="noopener noreferrer" href={v.link}>
                      {v.icon}
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className="language-button hlo" onClick={() => {setActiveModal(ModalEnum.Language)}}>        
          <span className="icon"><LanguageSvg/></span>
          <span className="text">{t("navi.language")}</span>
        </div>
        <div className="footer-legal">
          {/* <div>Made with care by <a target="_blank" rel="noopener noreferrer" href={Config.Common.CompanyLink}>{Config.Common.Company}</a> ❤️</div> */}
          <div>{Config.Common.AppName} &copy; {year}</div>
        </div>
      </div>
    </div>
  );

};

export default Footer;