import React,{useState,} from 'react';
import './entry.scss';

import CenterDiv from '../../common/center-div/center-div';
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import SignIn from './sign/sign-in';
import SignUp from './sign/sign-up';
import SnsButton from '../../elements/sns-button/sns-button';
import Logo from '../../common/logo/logo';
import {ConfigVariables} from '../../../config/config';
import { t } from '../../../translations/i18n';

const Entry = ({option,code,loginSuccessCallback,createAccountSuccessCallback}) => {
  let slideInit = 1;
  if(option !== null && option === "create-account"){;
    slideInit = 2;
  }

  if(code){
    localStorage.setItem("referralCode",code);
  }

  const snsOptions = ConfigVariables.SnsAuthMethods

  const [slide,setSlide] = useState(slideInit);
  const [check,setCheck] = useState(false);
  const checkUpdateFunc = (check) => { setCheck(check); }

  return (
    <div className="entry-comp">
      <CenterDiv>
        <div className="entry">
          <div className="entry-header">
            <Logo options="large"/>
          </div>
          <CarouselHeader className="entry-options hlo" slideTo={slide}>
            <div onClick={() => {setSlide(1)}}>{t("entry.signIn")}</div>
            <div onClick={() => {setSlide(2)}}>{t("entry.createAccount")}</div>
          </CarouselHeader>
          <Carousel slideTo={slide}>
            <div className="sign-in-slide"><SignIn successCallback={loginSuccessCallback}/></div>
            <div className="sign-up-slide"><SignUp successCallback={createAccountSuccessCallback} checkUpdateFunc={checkUpdateFunc}/></div>
          </Carousel>

          {(snsOptions.length <= 0)?null:
            <div className="entry-sns">
              <div className="line-div">
                <span className="line"></span>
                <span className="text">{t("entry.or")}</span>
                <span className="line"></span>
              </div>
              {snsOptions.map((d,i) => {return(<SnsButton key={i} snsType={d} checkNeeded={slide === 2 && !check} />)})}
            </div>
          }
          
        </div>
      </CenterDiv>
    </div>
    
  );

};

export default Entry;