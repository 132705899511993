export const roboRally = {
  "roboRally": {
    "en": "Robo Rally",
    "ja": "ロボラリー"
  },
  "myRobos": {
    "en": "My Robos",
    "ja": "マイ ロボ"
  },
  "roboRallyTracks": {
    "en": "Robo Rally Tracks",
    "ja": "ロボラリートラック"
  },
  "allRobos": {
    "en": "All Robos",
    "ja": "すべてのロボ"
  },
  "roboDetails": {
    "en": "Robo Details",
    "ja": "ロボ詳細"
  },
  "trackDetails": {
    "en": "Track Details",
    "ja": "トラック詳細"
  },
  "robos": {
    "en": "Robos",
    "ja": "ロボ"
  },
  "tracks": {
    "en": "Tracks",
    "ja": "トラック"
  },
  "newRobo": {
    "en": "New Robo",
    "ja": "新しいロボ"
  },
  "newTrack": {
    "en": "New Track",
    "ja": "新しいトラック"
  },
  "official": {
    "en": "Official",
    "ja": "公式"
  },
  "myTracks": {
    "en": "My Tracks",
    "ja": "私の"
  },
  "all": {
    "en": "All",
    "ja": "すべて"
  },
  "createRobo": {
    "en": "Create Robo",
    "ja": "ロボを作成"
  },
  "speed": {
    "en": "Speed",
    "ja": "速度"
  },
  "intelligence": {
    "en": "Intelligence",
    "ja": "知性"
  },
  "vision": {
    "en": "Vision",
    "ja": "視力"
  },
  "traction": {
    "en": "Traction",
    "ja": "トラクション"
  },
  "energy": {
    "en": "Energy",
    "ja": "燃費"
  },
  "name": {
    "en": "Name",
    "ja": "名前"
  },
  "edit": {
    "en": "Edit",
    "ja": "編集"
  },
  "delete": {
    "en": "Delete",
    "ja": "削除"
  },
  "prompt": {
    "en": "Prompt",
    "ja": "プロンプト"
  },
  "version": {
    "en": "Version",
    "ja": "バージョン"
  },
  "created": {
    "en": "Created",
    "ja": "作成日時"
  }
}