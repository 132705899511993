export const common = {
  edit: {
    en: "Edit",
    ja: "編集",
    et: "Muuda",
  },
  save: {
    en: "Save",
    ja: "保存",
    et: "Salvesta",
  },
  create:{
    en: "Create",
    ja: "作成",
    et: "Loo",
  },
  remove:{
    en: "Remove",
    ja: "解除",
    et: "Kustuta",
  },
  requestErr: {
    en: "Request Error: Refresh the page to try again. Contact us if this continues. ",
    ja: "リクエストエラー：ページを更新して再試行してください。 これが続く場合はお問い合わせください。",
    et: "Päringu viga: Lae leht uuesti. Võta ühendust, kui see viga jätkub."
  },
  resultsCount: {
    "en": "Showing {{start}}-{{stop}} of {{totalRes}} Results",
    "ja": "{{totalRes}} 件中 {{start}} - {{stop}} 件を表示中"
  },
  noResults:{
    "en": "No Results",
    "ja": "結果がありません"
  },
  month:{
    1:{ 
      en: "January", 
      ja: "一月",
      et: "Jaanuar",
    },
    2:{ 
      en: "February", 
      ja: "二月",
      et: "Veebruar",
    },
    3:{ 
      en: "March", 
      ja: "三月",
      et: "Märts",
    },
    4:{ 
      en: "April", 
      ja: "４月",
      et: "Aprill",
    },
    5:{ 
      en: "May", 
      ja: "５月",
      et: "Mai",
    },
    6:{ 
      en: "June", 
      ja: "６月",
      et: "Juuni",
    },
    7:{ 
      en: "July", 
      ja: "７月",
      et: "Juuli",
    },
    8:{ 
      en: "August", 
      ja: "８月",
      et: "August",
    },
    9:{ 
      en: "September", 
      ja: "９月",
      et: "September",
    },
    10:{ 
      en: "October", 
      ja: "１０月",
      et: "Oktoober",
    },
    11:{ 
      en: "November", 
      ja: "１１月",
      et: "November",
    },
    12:{ 
      en: "December", 
      ja: "１２月",
      et: "Detsember",
    },
  }
}