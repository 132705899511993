import React from 'react';
import './home.scss';
import {Link} from 'react-router-dom';

import FjorneyHomePng from '../../../css/imgs/home/fjorney-home.png';
import QueuedPromptsPng from '../../../css/imgs/home/queue-prompts.png';
import ApiPng from '../../../css/imgs/home/api.png';
import VariationPng from '../../../css/imgs/home/variation.png';

import EdgePng from '../../../css/imgs/edge.png';
import {ReactComponent as ChromeSvg} from '../../../css/imgs/chrome.svg';

import {ReactComponent as AddComputerSvg} from '../../../css/imgs/home/icon-add-computer.svg';
import {ReactComponent as SignUpSvg} from '../../../css/imgs/home/icon-signup-done.svg';
import {ReactComponent as InputSvg} from '../../../css/imgs/home/icon-input.svg';

import FAQ from '../faq/faq';

// let firefoxImgUrl = "https://cdn.cdnlogo.com/logos/m/87/mozilla-firefox.svg";
let googleChromeLink = "https://chromewebstore.google.com/detail/ad-begone/iflkofdiohdiklblnimdnpamkeijegcp?hl=en-US";
// let edgeLink = "";
// let firefoxLink = "";


const Home = () => {

  return (
    <div className="home-comp">
      <div className="section header1">
        <div className="inner boxer reverse">
          <div className="image">
            {/* <span><img  src={FjorneyHomePng} alt="home1"/></span> */}
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h1>Ad Begone</h1>
            <h4>Super Powers for Youtube</h4>
            <a className="button start-gen-btn" href={googleChromeLink} target="_blank">
              <div>Get it now</div>
              <span>It's free</span>
            </a>
            <div className='avail-div'>
              <div>Available on</div>
              <a target="_blank" href={googleChromeLink}><div className='icon'><ChromeSvg/></div><span>Google Chrome</span></a>
              {/* <a href={firefoxLink}><div className='image'></div><span>Firefox</span></a> */}
              {/* <a href={edgeLink}><div className='icon'><img src={EdgePng} alt="edge"/></div><span>Microsoft Edge</span></a> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section bg">
        <div className="inner boxer">
          <div className="image svg">
            <span><img  src={QueuedPromptsPng} alt="home2"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Queue Prompts</h2>
            <p>Midjourney automated. Easily create a prompt queue which automatically pings midjourney to generates images for you. </p>
            <p>You can also organize the prompts into folders to keep your workspace clean and easy to manage.</p>
          </div>
        </div>
      </div> */}
      {/* <div className="section">
        <div className="inner boxer reverse">
          <div className="image svg">
            <span><img  src={ApiPng} alt="home3"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Midjourney Api</h2>
            <p>Grab an api-key, check out our easy to understand docs and you're good to go. Check out our how-to-use-guide if you get lost. There's short videos explaing how to set it up. </p>
          </div>
        </div>
      </div> */}
      {/* <div className="section bg">
        <div className="inner boxer">
          <div className="image svg">
          <span><img  src={VariationPng} alt="home4"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Midjourney Prompt Generator</h2>
            <p>Have an idea of what you want but don't feel like writing a lengthy description for Midjourney? Gotcha covered there too. Just type in a few details and leave the rest to us. </p>
          </div>
        </div>
      </div> */}
      {/* <div className="section">
        <div className="inner boxer reverse">
          <div className="image svg">
            <span><img  src={VariationPng} alt="home5"/></span>
          </div>
          <div className="spacer"></div>
          <div className="text">
            <h2>Midjourney Prompt Variations</h2>
            <p>Have a prompt you like and want to generate similar prompts? Boom. That's right. We have that too.</p>
          </div>
        </div>
      </div> */}
      {/* <div className="section bg how-it-works-section">
        <div className="inner boxer">
          <div className="text">
            <h2>How it works</h2>
            <p>Add Fjorney as an extension to your browser. Add your Midjourney Discord URL link to the extension. Add some prompts and hit start. Done. Let Fjorney do the rest.</p>
            <p>If you want to use the api, grab an api key, leave a computer with fjorney installed and the browser open, hit our api endpoints and fjorney will automaically generate images from Midjourney.</p>
          </div>
        </div>
      </div> */}
      {/* <div className="section bg steps-section">
        <div className="inner boxer">
          <div className="text">
            <h2>Steps to set up</h2>
            <h4>Takes less than a minute</h4>
            <div className='steps'>
              <div className='step one'>
                <div className='num'>1</div>
                <div className='icon'><AddComputerSvg /></div>
                <span>Add Fjorney to your browser</span>
              </div>
              <div className='step two'>
                <div className='num'>2</div>
                <div className='icon'><SignUpSvg /></div>
                <span>Create a Fjorney account</span>
              </div>
              <div className='step three'>
                <div className='num'>3</div>
                <div className='icon'><InputSvg /></div>
                <span>Add your Discord URL & Prompts</span>
              </div>
            </div>
            <div className='how-to-use-note'>Check our <Link to="/how-to-use">How To Use</Link> guide if you get stuck anywhere.</div>
          </div>
        </div>
      </div> */}


      {/* <div className="section faq">
        <div className="inner boxer">
          <div className="text">
            <FAQ />
          </div>
        </div>
      </div>

      <div className='try-it-div'>
        <Link className='button try-it' to="/">Try it, it's free</Link>
      </div> */}
      
    </div>
  );
};

export default Home;