import React from 'react';
import {translations,} from './all';


const hasJSXPlaceholders = (str, options) => {
  const placeholders = str.match(/{{(.*?)}}/g) || [];  
  return placeholders.some(placeholder => {
    const key = placeholder.slice(2, -2);  
    return React.isValidElement(options[key]);  
  });
};


export const t = (key, options = {}) => {
  const lang = localStorage.getItem("language") || "en";

  let split = key.split(".");
  if(split.length !== 2){
    return key;
  }
  let file = split[0];
  let keyName = split[1];

  let translation = "";
  if (translations[lang] && translations[lang][file] && translations[lang][file][keyName]) {
    translation = translations[lang][file][keyName];  
  } else if(translations["en"] && translations["en"][file] && translations["en"][file][keyName]){
    translation = translations["en"][file][keyName];
  } else {
    return key;
  }

  if (hasJSXPlaceholders(translation, options)) {  
    let parts = translation.split(/{{(.*?)}}/); // Splits by placeholders
    return (
      <>
        {parts.map((part, index) => {
          if (options[part]) {
            return <React.Fragment key={index}>{options[part]}</React.Fragment>;
          }
          return <React.Fragment key={index}>{part}</React.Fragment>;
        })}
      </>
    );
  }

  for (let optionKey in options) {
    const replace = `{{${optionKey}}}`;
    translation = translation.replace(replace, options[optionKey]);
  }

  return translation
};
