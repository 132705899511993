import React, {useState, useEffect, useRef} from 'react';
import './carousel.scss';
import {ReactComponent as CarrotRightSvg} from '../../../css/imgs/icon-carrot-right.svg';
import {ReactComponent as CarrotLeftSvg} from '../../../css/imgs/icon-carrot-left.svg';


// Wrapped children components must be placed inside of <div> elements
const Carousel = ({slideTo, setSlide, sideSlider, slideCount, sideBarPadding, children}) => {

  const [carouselStyle, setCarouselStyle] = useState({});
  const activeRef = useRef(null);
  const index = slideTo - 1

  const newChildren = [];
  children.map((d,i) => {
    let addClass = (d.props.className !== undefined) ? d.props.className: ""
    const newProps = { 
      key: i 
    };

    if(i === index){
      addClass += " active"
      newProps.ref = activeRef;
    }

    newProps.className = addClass.trim();

    const newChild = React.cloneElement(d, newProps);

    newChildren.push(newChild);
    return false
  });

  let carouselContainerStyle = {
    left: (((slideTo * 100) - 100) * -1) + "%",
    width: (newChildren.length * 100)+ "%",
  }

  useEffect(() => {
    const interval = setInterval(function(){
      if(activeRef != null){
        // console.log("activeRef",activeRef);
        if(
          carouselStyle.height === undefined || 
          (activeRef !== null && activeRef.current !== null && activeRef.current.clientHeight !== null && carouselStyle.height !== activeRef.current.clientHeight)){
          setCarouselStyle({
            height: activeRef.current.clientHeight,
          });
        }
      }      
    },50);
    return () => {
      if(interval){ clearInterval(interval) }
    }
  },[carouselStyle]);

  useEffect(() => {
    if(activeRef.current !== null){
      setCarouselStyle({
        height: activeRef.current.clientHeight,
      });
    }
  },[slideTo]);

  let prevClass = "";
  let nextClass = "";
  let sideBarStylePrev = {}
  let sideBarStyleNext = {}
  if(sideSlider){
    prevClass = slideTo === 1?"fade":"";
    nextClass = slideTo === slideCount?"fade":"";
    sideBarPadding = sideBarPadding?sideBarPadding:0
    sideBarStylePrev = {
      height: `calc(100% - ${sideBarPadding*2}px)`,
      left: `${sideBarPadding}px`,
      top: `${sideBarPadding}px`,
    }
    sideBarStyleNext = {
      height: `calc(100% - ${sideBarPadding*2}px)`,
      right: `${sideBarPadding}px`,
      top: `${sideBarPadding}px`,
    }
  }
  return (
    <div className="carousel" style={carouselStyle}>
      {(sideSlider)?
        <div style={sideBarStylePrev} className={'side-slider prev '+prevClass} onClick={()=>{
          let newSlide = slideTo - 1;
          if(newSlide <= 1){newSlide = 1;}
          setSlide(newSlide)
        }}>
          <CarrotLeftSvg/>
        </div>
      :null}
      <div style={carouselContainerStyle} className="carousel-container">
        {newChildren}
      </div>
      {(sideSlider)?
        <div style={sideBarStyleNext} className={'side-slider next '+nextClass} onClick={()=>{
          let newSlide = slideTo + 1;
          if(newSlide >= slideCount){newSlide = slideCount;}
          setSlide(newSlide)
        }}>
          <CarrotRightSvg/>
        </div>
      :null}
    </div>
  );
};

export default Carousel;